<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_awal"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_awal"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_akhir"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_akhir"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Umur
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli Asal
                    </template>
                    <multiselect
                      :options="listPoli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli"
                      size="sm"
                      v-model="data_search.poli_asal"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli Tujuan
                    </template>
                    <multiselect
                      :options="listPoli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli"
                      size="sm"
                      v-model="data_search.poli_tujuan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group> -->
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <multiselect
                      :options="listAsuransi"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.asuransi"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="listDiagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nm_diag"
                      size="sm"
                      v-model="data_search.ms_diagnosa_id"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="listTenagaMedis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.ms_tenaga_medis_id"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr />
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_puskesmas"
                          name="check_puskesmas"
                          v-model="checkedPuskesmas"
                          @change="toggleSelectPuskesmas()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-checkbox  v-model="item.checked">
                          {{ item.nama_puskesmas }}
                        </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - RUJUK INTERNAL</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th rowspan="2" style="width: 10px; position:sticky; left:0; min-width:50px; background-color: #fff;">No</b-th>
                        <b-th rowspan="2" style="position:sticky; left:50px; min-width:150px; background-color: #fff;">Tanggal</b-th>
                        <b-th rowspan="2" style="position:sticky; left:200px; min-width:160px; background-color:#fff">Nama Pasien</b-th>
                        <b-th rowspan="2" style="min-width: 130px;">Jenis Kelamin</b-th>
                        <b-th rowspan="2" style="min-width: 200px;">Alamat</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Poli/Ruangan	</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Umur</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Asuransi</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">No Asuransi	</b-th>
                        <b-th colspan="6">Jumlah Biaya</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th rowspan="2" style="min-width: 150px;">Tenaga Medis</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Asisten</b-th>
                        <b-th rowspan="2" style="min-width: 250px;">Tindakan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Tarif</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in listRujukInternal" :key="idx">
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ item.no }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:50px;min-width:150px;background-color: #fff;">{{ $moment(item.waktu_mulai).format('LLLL') }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:200px;min-width:160px;background-color: #fff;">{{ item.nama_lengkap }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki' }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.alamat_sekarang }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.nama_poli }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.usia_tahun }} tahun {{ item.usia_bulan }} bulan {{ item.usia_hari }} hari</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{  }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{  }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].nama_tenaga_medis }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].asisten }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].nama_tindakan }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ $numberFormat(item.data_tindakan[0].total_tarif, 'idr') }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].jumlah_tindakan }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ $numberFormat(item.data_tindakan[0].jumlah_tarif_tindakan, 'idr') }}</b-td>

                        <b-td v-if="item.type == 'sub'">{{ item.nama_tenaga_medis }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.asisten }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.nama_tindakan }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ $numberFormat(item.total_tarif, 'idr') }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.jumlah_tindakan }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ $numberFormat(item.jumlah_tarif_tindakan, 'idr') }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- {{listRujukInternal}} -->
      </b-container>
    </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      total: null,
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        poli_asal: null,
        poli_tujuan: null,
        usia_tahun_mulai: null,
        usia_bulan_mulai: null,
        usia_hari_mulai: null,
        usia_tahun_selesai: null,
        usia_bulan_selesai: null,
        usia_hari_selesai: null,
        asuransi: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,
      },
      totalRows: 0,
      checkedPuskesmas: false,
      listPuskesmas: [],
      listRujukInternal: [],
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      listTenagaMedis: [],
      listPoli: [],
      listDiagnosa: [],
    items: [],
    filter: null,
    tableBusy: false,
    disabled_field: false,
    };
  },
  computed: {
  },
  activated(){
    this.getData()
    this.reset()
    this.getPuskesmas()
  },
  methods: {
    async toggleSelectPuskesmas () {
      const vm = this
      await new Promise(resolve => setTimeout(resolve, 1));
      for (let i = 0; i < vm.listPuskesmas.length; i++) {
        const data = vm.listPuskesmas[i];
        data.checked = vm.checkedPuskesmas;
      }
      vm.$forceUpdate()
    },
    async getPuskesmas(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        // const listPoli = await vm.$axios.post('/ms_poli/list')
        // vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listDiagnosa = await vm.$axios.post('/ms_diagnosa/list')
        vm.listDiagnosa = listDiagnosa.data.status == 200 ? listDiagnosa.data.data : []
        // let listTenagaMedis = await vm.$axios.post("/tenaga_medis/list", {nama_jenis_tenaga_medis: 'dokter'})
        // let listTenagaMedis = await vm.$axios.post("/tenaga_medis/list")
        // vm.listTenagaMedis = listTenagaMedis.data.status == 200 ? listTenagaMedis.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        //puskesmas
        const bulk_puskesmas_id = []
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const puskesmas = vm.listPuskesmas[i];
          if(puskesmas.checked){
            bulk_puskesmas_id.push(puskesmas.id)
          }
        }
        const res = await vm.$axios.post('/rujuk_internal/laporan_rujuk_internal', {
          tanggal_awal: vm.$moment(vm.data_search.tanggal_awal).format('YYYY-MM-DD'),
          tanggal_akhir: vm.$moment(vm.data_search.tanggal_akhir).format('YYYY-MM-DD'),
          poli_asal: vm.data_search.poli_asal ? vm.data_search.poli_asal.ms_poli_id : null,
          poli_tujuan: vm.data_search.poli_tujuan ? vm.data_search.poli_tujuan.ms_poli_id : null,
          asuransi: vm.data_search.asuransi ? vm.data_search.asuransi.value : null,
          ms_diagnosa_id: vm.data_search.ms_diagnosa_id ? vm.data_search.ms_diagnosa_id.ms_diagnosa_id : null,
          ms_tenaga_medis_id: vm.data_search.ms_tenaga_medis_id ? vm.data_search.ms_tenaga_medis_id.ms_tenaga_medis_id : null,
          bulk_puskesmas_id,
        })
        vm.listRujukInternal = []
        const listRujukInternal = []
        if(res.data.status == 200){
          vm.totalRows = res.data.data.length
          for (let i = 0; i < res.data.data.length; i++) {
            const x = res.data.data[i];
            listRujukInternal.push({
              ...x,
              no: i+1,
              type: 'head',
            })
            for (let o = 0; o < x.data_tindakan.length; o++) {
              if(o == 0) continue
              const y = x.data_tindakan[o];
              listRujukInternal.push({
                ...y,
                type: 'sub',
              })
            }
            vm.listRujukInternal = x
          }
          console.log('laporan_pendapatan_tindakan', res)
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
        poli_asal: null,
        poli_tujuan: null,
        usia_tahun_mulai: null,
        usia_bulan_mulai: null,
        usia_hari_mulai: null,
        usia_tahun_selesai: null,
        usia_bulan_selesai: null,
        usia_hari_selesai: null,
        asuransi: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
